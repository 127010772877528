@import '~font-awesome/css/font-awesome.css';

body{
  /* background-color: #fafaff; */
  background-color: #f1f5f9;
  /* background-color: #f6f6f6; */
}

section{
  max-width: 1200px;
  margin: 0 auto;
}

/* You can add global styles to this file, and also import other style files */
.overflow-hidden{
  overflow: hidden!important;
}

.text-right{
  text-align: right!important;
}

.row{
  margin: 0;
}

/* SIBS STYLE */
.paymentSPG{
  display: flex;
}

.modal-content{
  overflow: hidden;
}

.blur-3{
  /* filter: blur(3px); */
}