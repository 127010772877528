.btn-map-open-modal-user-location {
  width: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffffde;
  border-radius: 5px;
  text-align: right;
  color: #919191;
  margin: 5px;
}

.leaflet-bottom{
    display: block!important;
}

.leaflet-control-attribution{
    display: none!important;
}

/* .leaflet-marker-icon {
    opacity: 0;
    margin-top: -50px;
    animation: fadeIn 0.3s ease-in-out forwards;
    background-color: transparent;
    border: none;
} */

.leaflet-popup-pane {
  /* margin-top: 28px; */
}


@keyframes fadeIn {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: -25px;
  }
}