/* Sizes */
:root {
  --menu-height: 60px;
  --border-radius-0: 0;
  --border-radius: 5px;
  --border-radius-circle: 10000000px;
  /* --bs-border-color: #393f50; */
  --bs-border-color: #d1d2d2;
}

/* Shadows */
:root {
  --card-shadow: 3px 3px 5px 0px #0000001a;
}

/* Custom Color Variables */
:root {
  --primary-color: #5EDDC1; /* Blue */
  --primary-color-inverse: #b52c4f; /* red */
  /* --secondary-color: #8A79CB; purple */
  --secondary-color: #474848;/* Transparent Black */
  --success-color: #3ab54b; /* Green */
  --danger-color: #e53639; /* Red */
  --warning-color: #f39c12; /* Yellow */
  --info-color: #1276be; /* Blue */
  /*--light-bg-color: #f4f4f4; /* Light Gray */
  --light-bg-color: #ffffff; /* Light Gray */
  --dark-bg-color: #474848; /* Dark Gray */
  --dark-color: #393f50;

  --bs-primary: #5EDDC1;
  --bs-blue: #5EDDC1;
}

/* Lighter Variations */
:root {
  --primary-color-light: #6cdbc6; /* Lighter green */
  --secondary-color-light: #ac9fd2; /* Lighter Red */
  --success-color-light: #4ecc94; /* Lighter Green */
  --danger-color-light: #e85b5e; /* Lighter Red */
  --warning-color-light: #ffc53a; /* Lighter Yellow */
  --info-color-light: #4ea7e0; /* Lighter Blue */
  --light-bg-color-light: #f9f9f9; /* Lighter Gray */
  --dark-bg-color-light: #666; /* Dark Gray */
}

/* Darker Variations */
:root {
  --primary-color-dark: #50bda5; /* Darker Blue */
  --secondary-color-dark: #5d5475; /* Darker Red */
  --success-color-dark: #27ae60; /* Darker Green */
  --danger-color-dark: #ac282a; /* Darker Red */
  --warning-color-dark: #d35400; /* Darker Yellow */
  --info-color-dark: #297dbd; /* Darker Blue */
  --light-bg-color-dark: #dfdfdf; /* Lighter Gray */
  --dark-bg-color-dark: #222; /* Dark Gray */
}

* {
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: monospace; */
  font-family: 'open_sansregular', sans-serif, Arial;;
  font-size: 13px;
  padding: 0px;
}
body{
  padding: 0!important;
}

input.noborder {border:0;outline:0;}
input.noborder:focus {outline:none!important;}

/* Background Colors */
.bg-primary {
  background-color: var(--primary-color) !important;
}
.bg-secondary {
  background-color: var(--secondary-color) !important;
}
.bg-success {
  background-color: var(--success-color) !important;
}
.bg-danger {
  background-color: var(--danger-color) !important;
}
.bg-warning {
  background-color: var(--warning-color) !important;
}
.bg-info {
  background-color: var(--info-color) !important;
}
.bg-light {
  background-color: var(--light-bg-color) !important;
}
.bg-dark {
  background-color: var(--dark-bg-color) !important;
}
.bg-white {
  background-color: var(--light-bg-color) !important;
}
.btn-disable {
  opacity: 0.5;
}

/* Text Colors: */
.text-primary {
  color: var(--primary-color) !important;
}
.text-secondary {
  color: var(--secondary-color) !important;
}
.text-success {
  color: var(--success-color) !important;
}
.text-danger {
  color: var(--danger-color) !important;
}
.text-warning {
  color: var(--warning-color) !important;
}
.text-info {
  color: var(--info-color) !important;
}
.text-light {
  color: var(--light-bg-color) !important;
}
.text-dark {
  color: var(--dark-bg-color) !important;
}
.text-muted {
  color: #6c757d !important;
}

/* Buttons: */
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color-dark)!important;
}
.btn-primary:hover {
  background-color: var(--primary-color-dark);
}
.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color-dark)!important;
}
.btn-secondary:hover {
  background-color: var(--secondary-color-dark);
}
.btn-success {
  background-color: var(--success-color);
  border-color: var(--success-color-dark)!important;
}
.btn-success:hover {
  background-color: var(--success-color-dark);
}
.btn-danger {
  background-color: var(--danger-color);
  border-color: var(--danger-color-dark)!important;
}
.btn-danger:hover {
  background-color: var(--danger-color-dark);
}
.btn-warning {
  background-color: var(--warning-color);
  border-color: var(--warning-color-dark)!important;
}
.btn-warning:hover {
  background-color: var(--warning-color-dark);
}
.btn-info {
  background-color: var(--info-color);
  border-color: var(--info-color-dark)!important;
}
.btn-info:hover {
  background-color: var(--info-color-dark);
}
.btn-light {
  background-color: var(--light-bg-color);
  border-color: var(--light-bg-color-dark)!important;
}
.btn-light:hover {
  background-color: var(--light-bg-color-dark);
}
.btn-dark {
  background-color: var(--dark-bg-color);
  border-color: var(--dark-bg-color-dark)!important;
}
.btn-dark:hover {
  background-color: var(--dark-bg-color-dark);
}
.btn-link {
  color: var(--primary-color);
}
.btn-link:hover {
  color: var(--primary-color-dark);
}

/* Alerts: */
.alert-primary {
  color: var(--primary-color);
  background-color: var(--light-bg-color);
  border-color: var(--primary-color);
}
.alert-secondary {
  color: var(--secondary-color);
  background-color: var(--light-bg-color);
  border-color: var(--secondary-color);
}
.alert-success {
  color: var(--success-color);
  background-color: var(--light-bg-color);
  border-color: var(--success-color);
}
.alert-danger {
  color: var(--danger-color);
  background-color: var(--light-bg-color);
  border-color: var(--danger-color);
}
.alert-warning {
  color: var(--warning-color);
  background-color: var(--light-bg-color);
  border-color: var(--warning-color);
}
.alert-info {
  color: var(--info-color);
  background-color: var(--light-bg-color);
  border-color: var(--info-color);
}
.alert-light {
  color: var(--light-bg-color);
  background-color: var(--dark-bg-color);
  border-color: var(--light-bg-color);
}
.alert-dark {
  color: var(--dark-bg-color);
  background-color: var(--light-bg-color);
  border-color: var(--dark-bg-color);
}

/* Badges: */
.badge-primary {
  background-color: var(--primary-color);
  color: var(--light-bg-color);
}
.badge-secondary {
  background-color: var(--secondary-color);
  color: var(--light-bg-color);
}
.badge-success {
  background-color: var(--success-color);
  color: var(--light-bg-color);
}
.badge-danger {
  background-color: var(--danger-color);
  color: var(--light-bg-color);
}
.badge-warning {
  background-color: var(--warning-color);
  color: var(--light-bg-color);
}
.badge-info {
  background-color: var(--info-color);
  color: var(--light-bg-color);
}
.badge-light {
  background-color: var(--light-bg-color);
  color: var(--dark-bg-color);
}
.badge-dark {
  background-color: var(--dark-bg-color);
  color: var(--light-bg-color);
}

/* Labels: */
.label-primary {
  background-color: var(--primary-color);
  color: var(--light-bg-color);
}
.label-secondary {
  background-color: var(--secondary-color);
  color: var(--light-bg-color);
}
.label-success {
  background-color: var(--success-color);
  color: var(--light-bg-color);
}
.label-danger {
  background-color: var(--danger-color);
  color: var(--light-bg-color);
}
.label-warning {
  background-color: var(--warning-color);
  color: var(--light-bg-color);
}
.label-info {
  background-color: var(--info-color);
  color: var(--light-bg-color);
}
.label-light {
  background-color: var(--light-bg-color);
  color: var(--dark-bg-color);
}
.label-dark {
  background-color: var(--dark-bg-color);
  color: var(--light-bg-color);
}

/* Remove Leaflet bottom mark */
.leaflet-bottom {
  display: none !important;
}

.text-bold {
  font-weight: bold;
}

.card {
  box-shadow: var(--card-shadow);
  border: none;
  border-radius: var(--border-radius);
}

.card-img-top {
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}

.btn {
  /* border-radius: var(--border-radius-circle); */
}

.leaflet-container {
  z-index: 0;
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--light-bg-color);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}


.btn-primary {
  --bs-btn-color: white;
  --bs-btn-bg: var(--primary-color);
  --bs-btn-border-color: var(--primary-color-dark);
  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: var(--primary-color-dark);
  --bs-btn-hover-border-color: var(--primary-color-dark);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: white;
  --bs-btn-active-bg: var(--primary-color-dark);
  --bs-btn-active-border-color: var(--primary-color-dark);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: white;
  --bs-btn-disabled-bg: var(--primary-color);
  --bs-btn-disabled-border-color: var(--primary-color-dark);
}

.btn-outline-primary{
  --bs-btn-color: var(--primary-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--primary-color);
  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: var(--primary-color);
  --bs-btn-hover-border-color: var(--primary-color);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: white;
  --bs-btn-active-bg: var(--primary-color);
  --bs-btn-active-border-color: var(--primary-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: white;
  --bs-btn-disabled-bg: var(--primary-color);
  --bs-btn-disabled-border-color: var(--primary-color);

}

.btn-outline-secondary{
  --bs-btn-color: var(--secondary-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--secondary-color);
  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: var(--secondary-color);
  --bs-btn-hover-border-color: var(--secondary-color);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: white;
  --bs-btn-active-bg: var(--secondary-color);
  --bs-btn-active-border-color: var(--secondary-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: white;
  --bs-btn-disabled-bg: var(--secondary-color);
  --bs-btn-disabled-border-color: var(--secondary-color);
}

ul {
  border-radius: var(--border-radius) !important;
}

img {
  pointer-events: none;
}

.text-left{
  text-align: left!important;
}

.dropdown-toggle-no-arrow::after {
  display: none;
}

.form-control, .form-select{
  border-color: #d1d2d2;
  background-color: transparent!important;
}


.filter-invert-1{
  filter: invert(1)!important;
}


.filter-invert-1::selection {
  background-color: var(--primary-color-inverse)!important;
  border-color: black!important;
}

label.filter-invert-1 {
  color: #333;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

input.filter-invert-1, select.filter-invert-1{
  color: black!important;
  border: var(--bs-border-width) solid;
  border-color: black!important;
}

input.filter-invert-1:disabled, select.filter-invert-1:disabled {
  /* background-color: #ffffff56!important; */
  background-color: #ffffff8a!important;
}

input.filter-invert-1:focus, select.filter-invert-1:focus {
  border: var(--bs-border-width) solid!important;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25)!important;
}

label.filter-invert-1{
  color: white;
}

.filter-invert-0{
  filter: invert(0)!important;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: var(--primary-color)!important;
}

.box-shadow-none{
  box-shadow: none!important;
}

.transition-3{
  transition: all 0.3s ease-in-out;
}

.modal-content {
  overflow: hidden;
  border-radius: 30px;
}

.card{
  border-radius: 30px;
  overflow: hidden;
}