/* Change background color */
.swal2-popup {
    /* background-color: #ff9900; */
}

/* Change text color */
.swal2-title,
.swal2-content {
  color: var(--dark-bg-color)!important; 
}
.swal2-confirm{
    background: var(--primary-color)!important;
    color: var(--light-bg-color)!important;
}

.swal2-cancel{
    background-color: var(--secondary-color)!important;
    color: var(--light-bg-color)!important;
}

.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show{
    border-radius: var(--border-radius)!important;
}